import Vue from 'vue'

export default {
  state: {
    coins: [],
    rates: {},
    currencyType: {},
    xml: null
  },
  mutations: {
    setCoins (state, coins) {
      const cookie = document.cookie.match(new RegExp('(^| )language=([^;]+)'))
      let lang = cookie ? cookie[2] : 'ru'
      const urlParams = new URLSearchParams(window.location.search);
      const queryLang = urlParams.get('lang');
      if (queryLang === 'ru' || queryLang === 'en') {
        lang = queryLang
      }
      Vue.set(state, 'coins', coins
        .sort((a, b) => a.position - b.position)
      );
      Vue.set(state, 'currencyType', coins.reduce((result, item) => {
        const key = `${item.symbol}_${item.chain}`;
        result[key] = lang === 'en' && item.nameEn ? item.nameEn : item.name;
        return result;
      }, {}))
    },
    setRates (state, rates) {
      Vue.set(state, 'rates', rates.rates)
    },
    setXML (state, rates) {
      Vue.set(state, 'xml', rates.xml)
    }
  },
  actions: {
    async fetchCoins (ctx) {
      const coins = await Vue.$api.getCoins()
      ctx.commit('setCoins', coins?.data)
    },
    async fetchRates (ctx) {
      const rates = await Vue.$api.getRate()
      ctx.commit('setRates', rates?.data)
      ctx.commit('setXML', rates?.data)
    },
    async getRates () {
      const rates = await Vue.$api.getRate()
      return rates?.data.rates
    },

    async updateXML (ctx) {
      const rates = await Vue.$api.updateXML()
      ctx.commit('setRates', rates?.data)
      ctx.commit('setXML', rates?.data)
    },
    async updateCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.updateCoinByAdmin({ segments: { id: params._id }, data: params })
      ctx.commit('setCoins', coins?.data)
      return coins?.data
    },
    async saveCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.saveCoinByAdmin({ data: params })
      ctx.commit('setCoins', coins?.data)
      return coins?.data
    },
    async deleteCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.deleteCoinByAdmin({ segments: { id: params } })
      ctx.commit('setCoins', coins?.data)
    },
  }
}
