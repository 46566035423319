import Vue from 'vue';

const status = {
  ru: {
    NEW: 'Новый',
    PENDING: 'В обработке',
    PAID: 'Поступила оплата',
    COMPLETED: 'Завершен',
    TIMEEND: 'Истекло время',
    AMLRISK: 'AML риск',
    CANCELED: 'Отменена',
    ERROR: 'Ошибка',
    PARTIALLYPAID: 'Оплачен частично',
    ERRORDETAILS: 'Ошибка в реквизитах'
  },
  en: {
    NEW: 'New',
    PENDING: 'Pending',
    PAID: 'Paid',
    COMPLETED: 'Completed',
    TIMEEND: 'Expired',
    AMLRISK: 'AML risk',
    CANCELED: 'Canceled',
    ERROR: 'Error',
    PARTIALLYPAID: 'Partially paid',
    ERRORDETAILS: 'Wrong details'
  }
};

function getLanguageFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
  try {
    const lang = (new URLSearchParams(window.location.search)).get('lang')
    if (lang === 'ru' || lang === 'en') {
      return lang
    }
  } catch (e) { console.log(e) }
  return match ? match[2] : 'ru'; // Если cookie не найдены, по умолчанию используем 'ru'
}

// Установим локаль на основе cookie
const language = getLanguageFromCookie();
Vue.prototype.$status = status[language];

export default status;